<template>
  <div v-if="commonCodeByOrder && gameCount" class="pt173 mainBg2">
    <section id="maincontent">
      <transition name="fade">
        <ui-swiper />
      </transition>
    </section>
    <div class="mainBg">
       <section class="mt50 mb120">
          <section>
            <h2 class="gameTitle">인기게임</h2>
            <div class="bigbanner">
               <div class="first">
                  <ul class="betGames betGamesd">
                      <li>
                        <a @click="onCasinoSelectGame('sport', 'splus')">
                           <div class="front spl">
                              <em class="glogo"></em>
                           </div>
                        </a>
                        <p class="name">
                          SPLUS 스포츠
                          <a @click="gamebox=!gamebox"><img src="@/assets/img/icon_qusetion.svg" /></a>
                        </p>
                        <span class="lbest"></span>
                      </li>
                   </ul>
               </div>
               <ul class="betGames betGamesd">
                   <li>
                     <a @click="onCasinoSelectGame(casino, 1)">
                        <div class="front ev">
                           <em class="glogo ev"></em>
                        </div>
                     </a>
                     <p class="name">
                       에볼루션
                       <a @click="gamebox=!gamebox"><img src="@/assets/img/icon_qusetion.svg" /></a>
                     </p>
                     <span class="lbest"></span>
                   </li>
                   <li>
                     <a @click="slotOpen('isSlot', 'slot', '프라그마틱플레이 슬롯', 200)">
                        <div class="front prs">
                           <em class="glogo prs"></em>
                        </div>
                     </a>
                     <p class="name">
                       프라그마틱 슬롯
                       <a @click="gamebox=!gamebox"><img src="@/assets/img/icon_qusetion.svg" /></a>
                     </p>
                     <span class="lhot"></span>
                   </li>
                   <li>
                    <a @click="onCasinoSelectGame('minigame', 33)">
                        <div class="front hl">
                           <em class="glogo"></em>
                        </div>
                     </a>
                     <p class="name">
                       하이로우
                       <a @click="gamebox=!gamebox"><img src="@/assets/img/icon_qusetion.svg" /></a>
                     </p>
                     <span class="lhot"></span>
                   </li>
                   <li>
                     <a @click="onCasinoSelectGame(casino, 10)">
                        <div class="front prc">
                           <em class="glogo prc"></em>
                        </div>
                     </a>
                     <p class="name">
                       프라그마틱 카지노
                       <a @click="gamebox=!gamebox"><img src="@/assets/img/icon_qusetion.svg" /></a>
                     </p>
                     <span class="lnew"></span>
                   </li>
                   <li>
                    <a @click="onCasinoSelectGame(casino, 13)">
                        <div class="front bota">
                           <em class="glogo bota"></em>
                        </div>
                     </a>
                     <p class="name">
                       보타
                       <a @click="gamebox=!gamebox"><img src="@/assets/img/icon_qusetion.svg" /></a>
                     </p>
                     <span class="lhot"></span>
                   </li>
                   <li>
                    <a @click="onCasinoSelectGame('minigame', 'niu')">
                        <div class="front niu">
                           <em class="glogo"></em>
                        </div>
                     </a>
                     <p class="name">
                       니우니우
                       <a @click="gamebox=!gamebox"><img src="@/assets/img/icon_qusetion.svg" /></a>
                     </p>
                     <span class="lhot"></span>
                   </li>
               </ul>
            </div>
          </section>
          <section>
            <h2 class="gameTitle">SPORTS</h2>
            <ul class="w1400 betGames betGamesa">
              <template v-for="item in commonCodeByOrder['sport']" v-bind:key="item.vendorKey">
                <li v-if="item.groupCodeName.indexOf('HC-') == -1">
                  <a @click="onCasinoSelectGame(item.groupCode, item.code)">
                     <div class="front" :style="getBackgroundImage(item)">
                        <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
                     </div>
                  </a>
                  <p class="name">
                    {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                    <em :class="item.groupCode">{{ $t('front.gnb.sport') }}</em>
                    <a @click="gamebox=!gamebox"><img src="@/assets/img/icon_qusetion.svg" /></a>
                  </p>
                  <!--span class="lbest">{{ $t('front.main.new') }}</span-->
                </li>
              </template>
            </ul>
          </section>
          <section>
            <h2 class="gameTitle">LIVE CASINO</h2>
            <ul class="w1400 betGames betGamesa">
              <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.vendorKey">
                <li v-if="item.groupCodeName.indexOf('HC-') == -1">
                  <a @click="onCasinoSelectGame(item.groupCode, item.code)">
                     <div class="front" :style="getBackgroundImage(item)">
                        <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
                     </div>
                  </a>
                  <p class="name">
                    {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                    <em :class="item.groupCode">{{ $t('front.gnb.livecasino') }}</em>
                    <a @click="gamebox=!gamebox"><img src="@/assets/img/icon_qusetion.svg" /></a>
                  </p>
                  <!--span class="lbest">{{ $t('front.main.new') }}</span-->
                </li>
              </template>
            </ul>
          </section>
          <!-- <section>
            <h2 class="gameTitle">HOTEL CASINO</h2>
            <ul class="w1400 betGames betGamesc">
              <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.vendorKey">
                <li v-if="item.groupCodeName.indexOf('HC-') > -1">
                  <a @click="onCasinoSelectGame(item.groupCode, item.code)">
                     <div class="front" :style="getBackgroundImage(item)">
                        <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
                     </div>
                  </a>
                  <p class="name">
                    {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                    <em :class="item.groupCode">{{ $t('front.gnb.hotelcasino') }}</em>
                    <a @click="gamebox=!gamebox"><img src="@/assets/img/icon_qusetion.svg" /></a>
                  </p>
                  span class="lbest">{{ $t('front.main.new') }}</span
                </li>
              </template>
            </ul>
          </section> -->
          <section>
            <h2 class="gameTitle">SLOT GAME</h2>
            <ul class="w1400 betGames betGamesb">
              <template v-for="item in commonCodeByOrder['slot']" v-bind:key="item.vendorKey">
                <li v-if="item.groupCodeName.indexOf('H-') === -1">
                  <a @click="slotOpen('isSlot', 'slot', item.codeName, item.code)">
                     <div class="front" :style="getBackgroundImage(item)">
                       <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
                     </div>
                  </a>
                  <p class="name">
                    {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                    <em :class="item.groupCode">{{ $t('front.gnb.slotgame') }}</em>
                    <a @click="gamebox=!gamebox"><img src="@/assets/img/icon_qusetion.svg" /></a>
                  </p>
                </li>
              </template>
            </ul>
          </section>
          <section>
            <h2 class="gameTitle">MINI GAME</h2>
            <ul class="w1400 betGames betGamesa">
              <template v-for="item in commonCodeByOrder['minigame']" v-bind:key="item.vendorKey">
                <li v-if="item.groupCodeName.indexOf('HC-') == -1">
                  <a @click="onCasinoSelectGame(item.groupCode, item.code)">
                     <div class="front" :style="getBackgroundImage(item)">
                        <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
                     </div>
                  </a>
                  <p class="name">
                    {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                    <em :class="item.groupCode">{{ $t('front.gnb.livecasino') }}</em>
                    <a @click="gamebox=!gamebox"><img src="@/assets/img/icon_qusetion.svg" /></a>
                  </p>
                  <!--span class="lbest">{{ $t('front.main.new') }}</span-->
                </li>
              </template>
            </ul>
          </section>
       </section>
    </div>
    <div class="mainBg">
      <section>
        <div class="listwrap">
           <div class="servicecenter">
               <h4 class="name">{{ $t('front.common.notice') }}</h4>
               <ul>
                 <template v-for="item in noticeList" :key="item">
                  <li @click="onClickNoticeRead(item)">
                     <span>{{item.title}}</span>
                     <span>{{item.regDate}}</span>
                  </li>
                 </template>
                 <template v-for="item in listMaxCount - noticeList.length" :key="item">
                  <li>
                     <span>&nbsp;</span>
                     <span>&nbsp;</span>
                  </li>
                 </template>
               </ul>
           </div>
           <div class="inoutwrap">
               <h4 class="name">{{ $t('front.main.livecharge') }}</h4>
               <div>
                  <div class="inout">
                     <ul>
                       <template v-for="item in cashInList" :key="item.memId">
                         <li>
                           <span><img src="@/assets/img/icon_inin.svg" />{{item.memId}}</span>
                           <span>{{thousand(Number(item.cashAmt))}} 원</span>
                           <span>{{item.regDate}}</span>
                         </li>
                       </template>
                       <template v-for="item in listMaxCount - cashInList.length" :key="item">
                         <li>
                           <span>&nbsp;</span>
                           <span>&nbsp;</span>
                           <span>&nbsp;</span>
                         </li>
                       </template>
                     </ul>
                  </div>
               </div>
           </div>
           <div class="inoutwrap">
               <h4 class="name">{{ $t('front.main.liveExchange') }}</h4>
               <div>
                  <div class="inout">
                     <ul>
                       <template v-for="item in cashOutList" :key="item.memId">
                         <li>
                           <span><img src="@/assets/img/icon_outout.svg" />{{item.memId}}</span>
                           <span>{{thousand(Number(item.cashAmt*-1))}} 원</span>
                           <span>{{item.regDate}}</span>
                         </li>
                       </template>
                       <template v-for="item in listMaxCount - cashOutList.length" :key="item">
                         <li>
                           <span>&nbsp;</span>
                           <span>&nbsp;</span>
                           <span>&nbsp;</span>
                         </li>
                       </template>
                     </ul>
                  </div>
               </div>
           </div>
        </div>
      </section>
   </div>
     <div class="banner2">
         <ul>
             <li class="help">도움이 필요하세요?</li>
             <li>
                <span>고객센터</span>
                <div class="call">
                   <!-- <em><img src="@/assets/img/tell.svg" />텔레그램 @Acro119</em> -->
                </div>
             </li>
             <li>
                <span>운영시간 : 24시간</span>
                <span>아크로의 모든 상담원은 24시간<br />고객님을 위해 대기하고 있습니다.</span>
             </li>
             <li class="btn">
                <a class="btn1" @click="goPageByName('cscenter')">고객센터</a>
                <a class="btn2" @click="goPageByName('qna')">1 : 1 문의</a>
             </li>
         </ul>
     </div>
     <div class="banner4">
        <ul>
           <li><img src="@/assets/img/chrome.svg" /></li>
           <li><img src="@/assets/img/twitter.svg" /></li>
           <li>크롬 브라우져사용을 권장합니다.</li>
        </ul>
     </div>

      <div class="banner3 mainBg">
         <h4>공식 파트너사</h4>
         <transition name="fade">
           <ui-swipera />
         </transition>
      </div>
      <div class="mainPopWrap">
        <template v-for="(item) in popupList" v-bind:key="item.bannerIdx">
          <div class="mainPop" v-if="item.delYn == 'N' && !item.isClose" :data-idx="item.bannerIdx">
            <div class="closeWrap">
               <a class="close" @click="popupCloseByCookie(item)">{{$t('front.main.today')}}</a>
               <a class="close" @click="popupClose(item)">{{$t('front.main.close')}}</a>
            </div>
            <img :src="item.bannerImg" />
          </div>
        </template>
      </div>

  </div>
  <!--div v-if="gamebox" class="gamebox">
    <a @click="gamebox=!gamebox" class="close"><img src="@/assets/img/icon_cancelB.svg" /></a>
    <h4>에볼루션</h4>
    <pre>베팅금 : 최소 1,000,000 - 최대 100,000,000

1:1 VIP 전용 테이블, 무제한 슈 체인지
베팅시간의 시간적 제약이 없어
자신의 페이스에 맞게 플레이 가능!
다른 딜러를 원하실 경우 대화창에 딜러 교체 요청!</pre>
    <a @click="gamebox=!gamebox" class="check">확인</a>
 </div-->

   <!--div class="loginmodal">
      <div class="mloading-container">
         <div class="mloading"></div>
         <div class="mloading-text">loading</div>
      </div>
   </div-->
</template>

<script>

import '@/styles/common.css'
import { getPopupCookie, setPopupCookie, tokenCheck } from '@/libs/auth-helper'
import { getMain, getMainCashStatus, getMainNotice } from '@/api/main'
import { mapState } from 'vuex'
import store from '@/store'
import UiSwiper from '@/components/ui/UiSwiper.vue'
import UiSwipera from '@/components/ui/UiSwipera.vue'
// import UiSwiperb from '@/components/ui/UiSwiperb.vue'
import { thousand } from '../libs/utils'

export default {
  name: 'Index.vue',
  components: {
    UiSwiper,
    UiSwipera
    // UiSwiperb
  },
  created () {
    // this.emitter.emit('Loading', true)
    // this.emitter.emit('Loading', true)
    // setTimeout(() => {
    //
    // }, 5000)
    // setTimeout(() => {
    //   this.emitter.emit('Loading', false)
    // }, 8000)
    this.loadMain()
    this.popupMerge()
    this.loadNotice()
    this.loadCashStatus()
  },
  data () {
    return {
      type: 'in',
      currentTab: '',
      popupList: {},
      cashInList: [],
      cashOutList: [],
      noticeList: [],
      listMaxCount: 7,
      selectedMenu: '',
      noticeListMaxCount: 7,
      gamebox: false
    }
  },
  watch: {
    popup () {
      this.popupMerge()
    },
    popupLogin () {
      this.popupMerge()
    },
    async userData () {
      await store.dispatch('storePopup')
      if (this.userData) {
        await store.dispatch('storePopupLogin')
      }
      await this.popupMerge()
    }
  },
  computed: {
    ...mapState([
      'userData',
      'commonCode',
      'commonCodeByOrder',
      'popup',
      'popupLogin',
      'banner',
      'gameCount'
    ])

  },
  methods: {
    onClickNoticeRead (item) {
      console.log(item)
      this.$router.push({ name: 'noticeRead', params: { boardIdx: item.boardIdx } })
    },
    thousand,
    loadCashStatus () {
      getMainCashStatus({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result.data)
          this.cashInList = result.data.cashInList.slice(0, this.listMaxCount)
          this.cashOutList = result.data.cashOutList.slice(0, this.listMaxCount)
        }
      })
    },
    loadNotice () {
      getMainNotice({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result)
          this.noticeList = result.data.boardList.slice(0, this.noticeListMaxCount)
        }
      })
    },
    getBackgroundImage (item) {
      console.log('게임목록', this.commonCodeByOrder)
      try {
        const image = require('../assets/img/gamecardNew/game_main_pc_' + item.code + '_off.png')
        const imageOff = require('../assets/img/gamecardNew/game_main_pc_' + item.code + '_off.png')
        if (image && imageOff) {
          if (item.isHover) {
            return { backgroundImage: 'url(' + image + ')' }
          } else {
            return { backgroundImage: 'url(' + imageOff + ')' }
          }
        } else {
          return { backgroundImage: 'url()' }
        }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    },
    getBackgroundLogoImage (item) {
      try {
        const image = require('../assets/img/glogo' + item.code + '.png')
        return { backgroundImage: 'url(' + image + ')' }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    },
    popupMerge () {
      const popupLogin = this.userData ? this.popupLogin : []
      const pList = this.popup.concat(popupLogin)

      this.popupList = {}

      pList.forEach(item => {
        if (!item.isClose) {
          const key = item.bannerIdx
          const siteId = process.env.VUE_APP_SITE_ID || 'moka'
          const name = siteId + '_popup_' + item.bannerIdx
          const cookie = getPopupCookie(name)
          if (cookie) {
            item.isClose = true
          } else {
            item.isClose = false
          }
          if (!this.popupList[key]) {
            this.popupList[key] = item
          }
        }
      })
    },
    popupClose (item) {
      item.isClose = true
    },
    popupCloseByCookie (item) {
      item.isClose = true
      setPopupCookie(item)
    },
    slotOpen (event, groupCode, codeName, code) {
      this.emitter.emit(event, { groupCode, codeName, code })
      this.emitter.emit('Loading', false)
    },
    loadMain () {
      if (this.userData) {
        getMain({}).then(res => {
          // console.log(res)
        })
      }
    },
    onChangeType (type) {
      this.type = type
    }
  },
  async beforeRouteEnter (to, from, next) {
    await tokenCheck()
    // if (result) {
    next()
    // }
  }
}
</script>
<style scoped>
.mt111 {height: 400px}
.loading {z-index: 10;position: absolute;top: 0;width: 100%;height: 310px;padding-top: 40px;display: flex;align-items: center;justify-content: center;}
.loading img {height: 100%;}

h2.mainnames {width: 1314px;margin: 0 auto;padding: 30px 0 20px;font-size: 30px;font-weight: bold;color: #000;}
.name em.casino {color: #dc4a42;}
.name em.slot {color: #f4d03b;}
.name em.hotel {color: #fc7c30;}
.name em.sport {color: #244694;}
.name em.mini {color: #693290;}
li:hover .name, li:hover .name em {color: #fff;}

ul.tabs {display: flex;justify-content: center;align-items: center;padding: 30px 0;width: 1314px;margin: 0 auto;gap: 5px;}
ul.tabs li {display: flex;align-items: center;justify-content: center;font-size: 14px;color: #fff;cursor: pointer;width: 148px;height: 30px;border: solid 1px #777;background: #111;}
.tab-content{margin: 0 auto;}
.tab-content.current{display: inherit;}
ul.tabs li:hover {border: 1px solid #e5972d;background: #333;}
ul.tabs li.current {color: #000;border: 1px solid #e5972d;background: #e5972d;}

.slotmodalwrap::-webkit-scrollbar {width: 10px;}
.slotmodalwrap::-webkit-scrollbar-thumb {background-color: none;border-radius: 10px;background-clip: padding-box;border: 2px solid transparent;}
.slotmodalwrap::-webkit-scrollbar-track {background-color: none;border-radius: 10px;}
.slotmodal {background: #ededed;border: 1px solid #707070;border-radius: 30px;position: absolute;left: 50%;top: 50%; transform: translate(-50%,-50%);z-index: 1;width: 1314px;}
.slotmodal>h3 {width: 100%;position: relative;text-align: center;font-size: 30px;font-weight: bold;color: #fff;padding: 15px 0;background: #222;border-radius: 30px 30px 0 0;}
.slotmodal>h3::after {content: '';display: block;width: 100%;height: 17px;background-image: linear-gradient(to right, #319e8a, #1c594e);position: absolute;bottom: -25px;}
.slotmodal>h3 .close {position: absolute;right: 15px;top: 9px;height: 40px;}
.slotmodalwrap {max-height: 900px;overflow-y: auto;padding-top: 55px;}
.slotmodalwrap ul {padding: 0 65px 30px;display: flex;gap: 25px;justify-content: center;}
.slotmodalwrap ul li {transition: all ease 1s;cursor: pointer;}
.slotmodalwrap ul li:hover, .slotmodal ul li:focus, .slotmodal ul li:active {transform: scale(1.1);}
.slotmodalwrap ul li img {width: 100%;border-radius: 12px;border: 2px solid #319e8a;height: 200px;}

.mainPopWrap {display: flex;align-items: center;gap: 50px;position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);z-index: 100;}
.mainPop {position: relative;min-width: 320px;background: #fff;border: 1px solid #777;z-index: 100;}
.mainPop .closeWrap {width: calc(100% - 30px);display: flex;align-items: center;justify-content: end;gap: 15px;position: absolute;bottom: 0;padding: 15px;background: rgba(0,0,0,.7);color: #fff;font-size: 12px;}
.mainPop a.close {color: #fff;}
.mainPop>img {width: 430px;height: 640px;vertical-align: bottom;object-fit: cover;object-position: center;}

</style>
<style>
.swiper-button-next {background-image: url(../assets/img/slideright.png) !important;}
.swiper-button-prev {background-image: url(../assets/img/slideleft.png) !important;}
</style>
<style scoped src="@/styles/header.css"></style>
<style scoped src="@/styles/footer.css"></style>
<style scoped src="@/styles/main.css"></style>
